@import './../../../../scss/theme-bootstrap';

.spp-sticky {
  @include font-smoothing(none);
  display: none;
  background: $color-white;
  &__container {
    width: 100%;
    max-width: $max-width;
    margin: auto;
    display: flex;
    align-content: stretch;
    align-items: center;
    justify-content: space-between;
    color: $color-black;
    border-bottom: 1px solid $color-border;
  }
  &__cell {
    flex: 0 0 auto;
    order: 0;
    padding: 0 15px;
    &--info {
      flex: 1 0 400px;
      .product-full__name {
        font-size: 16px;
        text-transform: uppercase;
      }
      .product-full__subline {
        font-size: 26px;
        text-transform: none;
      }
    }
    &--select {
      flex: 0 0 290px;
    }
    &--bag {
      flex: 0 0 230px;
      padding: 10px 0;
      position: relative;
      html.pro-user & {
        flex: 0 0 300px;
      }
      & .product__button--shop-wholesale,
      & br.pro-content-element {
        display: none;
      }
      .product__inventory-status {
        &-container {
          margin-bottom: 0;
          position: absolute;
        }
        &-item {
          text-align: #{$ldirection};
        }
      }
    }
  }
  &__image {
    max-width: 105px;
  }
  .product-full__ratings {
    text-align: left;
    .pr-snippet {
      text-align: left !important;
    }
    .pr-snippet-stars-reco-reco {
      display: none;
    }
    .pr-snippet-stars-reco-stars {
      float: none !important;
    }
    .pr-snippet-stars-container,
    .pr-snippet-read-and-write {
      display: inline-block !important;
      text-align: left;
    }
    .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-read-and-write {
      display: block;
    }
    .pr-snippet-read-and-write a {
      color: $color-reviews !important;
      font-family: $base-font-family !important;
      text-decoration: underline;
      font-size: 11px !important;
    }
  }
  .sku-menu__single {
    display: none;
  }
  .button {
    @include button-large--desktop;
    @include breakpoint($large-up) {
      padding: 11px 25px 10px 25px;
      min-width: 210px;
    }
  }
  .countdown-timer ~ & {
    margin-top: $countdown-banner-height-mobile;
    @include breakpoint($landscape-up) {
      margin-top: $countdown-banner-height-desktop;
    }
  }
}
